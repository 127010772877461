<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Customers from '@/services/Customers';
  import Cashless from '@/services/Cashless';
  import General from '@/services/General';
  import modalAddCustomer from '@/components/modals/customers/modalAddCustomer';
  import modalAddCustomerFromTicketing from '@/components/modals/customers/modalAddCustomerFromTicketing';
  import DatePicker from "vue2-datepicker";
  import Multiselect from "vue-multiselect";
  import moment from "moment";
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
  import _ from 'underscore';
  import CustomersMap from "@/components/widgets/customersMap";
  import Auth from '@/services/Auth';

  export default {
  components: { Layout, PageHeader, modalAddCustomer, DatePicker, Multiselect, VueBootstrapTypeahead, CustomersMap, modalAddCustomerFromTicketing },
  page: {
      title: "Users",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  watch: {

    'filterInput.first_name': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'firstname') }, 500),
    'filterInput.last_name': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'lastname')}, 500),
    'filterInput.email': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'email')}, 500),
    'filterInput.provider_ticketing_id': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'provider_ticketing_id')}, 500),
    'filterInput.address': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'address')}, 500),
    'filterInput.postal': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'postal')}, 500),
    'filterInput.city': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'city')}, 500),

    'filterInput.ewallet_serial': _.debounce(function(search) { this.getCardsAutocomplete(search, 'ewallet_serial')}, 500),
  },
  data() {
    return {
        isAuthorized: false,
        accessView: JSON.parse(process.env.VUE_APP_SIDE_MENU),
        error: null,
        filterData: false,
        showMapData: false,
        showMap: false,
        tableData: [],
        title: "Customers",
        showButtonCreateUserFromTicketing: false,
        items: [
          {
            text: "CRM",
            href: "/customers",
          },
          {
            text: "Users",
            active: true,
          },
        ],
        genderOptions: [
          { text: 'Male', value: 'M' },
          { text: 'Female', value: 'F' },
          { text: 'Other', value: 'O' },
          { text: 'None', value: '' },
        ],
        userAttributes: [
          { text: 'email_club', value: 'opt_in_email_club' },
          { text: 'email_partners', value: 'opt_in_email_partners' },
          { text: 'mail_club', value: 'opt_in_mail_club' },
          { text: 'phone_club', value: 'opt_in_phone_club' },
          { text: 'sms_club', value: 'opt_in_sms_club' }
        ],
        ticketing_client_types:[],
        userStatuses: [],
        filterInput: {
          first_name: '',
          last_name: '',
          gender: [],
          email: '',
          birthdate: '',
          birthdates_temp: [],
          userStatus: [],
          attributes: [],
          created: '',
          address: '',
          city: '',
          postal: '',
          country: '',
          provider_ticketing_id:'',
          ewallet_serial:'',
          season_ticket:'',
        },
        customerData: [],
        countries: [],
        isBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
            {
              key: "first_name",
              label: "Customer",
              sortable: true,
            },
            {
              key: "email",
              label: "Email",
              sortable: true,
            },
            {
              key: "date_of_birth",
              label: "Birth Date",
              sortable: true,
            },
            {
              key: "gender",
              label: "Gender",
              sortable: true,
            },
            {
              key: "created_on",
              label: "Join Date",
              sortable: true,
            },
            {
              key: "status",
              label: "Status",
              sortable: true,
            },
            "action"
        ],
        
    };
  },
  computed: {

    attributeVals() {
      return this.filterInput.attributes.map(v => v.value);
    },
    countryVals() {
      return this.filterInput.country.map(v => v.name);
    },
  },
  async created() {
    this.checkButtonCreateFromTicketing()
    this.getUserStatuses()
    this.getCountry()
  },
  methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      async getCustomersAutocomplete(query, inputTypeName) {
        this.customerData = []

        if(query.trim().length > 0){
          if(inputTypeName == 'firstname'){
            query = 'first_name='+query
          }else if(inputTypeName == 'lastname'){
            query = 'last_name='+query
          }else if(inputTypeName == 'email'){
            query = 'email='+query
          }else if(inputTypeName == 'provider_ticketing_id'){
            query = 'provider_ticketing_id='+query
          }else if(inputTypeName == 'address'){
            query = 'address='+query
          }else if(inputTypeName == 'postal'){
            query = 'postal='+query
          }else if(inputTypeName == 'city'){
            query = 'city='+query
          }else if(inputTypeName == 'country'){
            query = 'country='+query
          }

          const response = await Customers.getCustomersByFilter(query)
          const rawData = await response
          var suggestions = [];
          rawData.data.data.forEach(function (item) {
            if(item.first_name && inputTypeName === 'firstname'){
              suggestions.push({text:item.first_name});
            }else if(item.last_name && inputTypeName === 'lastname'){
              suggestions.push({text:item.last_name});
            }else if(item.email && inputTypeName === 'email'){
              suggestions.push({text:item.email});
            }else if(item.ticketing_provider_id && inputTypeName === 'provider_ticketing_id'){
              suggestions.push({text:item.ticketing_provider_id});
            }else if(item.address_line1 && inputTypeName === 'address'){
              suggestions.push({text:item.address_line1 + ' ' + item.address_line2});
            }else if(item.address_postal && inputTypeName === 'postal'){
              suggestions.push({text:item.address_postal});
            }else if(item.address_city && inputTypeName === 'city'){
              suggestions.push({text:item.address_city});
            }else if(item.address_country && inputTypeName === 'country'){
              suggestions.push({text:item.address_country});
            }
          });

          this.customerData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
        }
      },

      async getCardsAutocomplete(query, inputTypeName) {
        this.customerData = []

        if(query.trim().length > 0){
          if(inputTypeName === 'ewallet_serial'){
            query = 'serial='+query
          }
          const response = await Cashless.getCards(query)
          const rawData = await response
          var suggestions = [];
          rawData.data.data.forEach(function (item) {
            if(item.serial && inputTypeName === 'ewallet_serial'){
              suggestions.push({text:item.serial});
            }
          });

          this.customerData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
        }
      },

      async checkButtonCreateFromTicketing(){
          Auth.hasObjectPermission({
              object: 'customers.button.CreateUserFromTicketing'
          }).then(() => {
              this.showButtonCreateUserFromTicketing = true
          }).catch(() => {
              this.showButtonCreateUserFromTicketing = false
          })
      },

      async getCustomers(){
          try {
            this.filterData = true;
            this.toggleBusy();
            var filters = this.getFilters()
            const response = await Customers.getCustomersByFilter(filters)
            this.tableData = response.data.data
            this.totalRows = this.tableData.length
          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
            this.totalRows = 0;
          } finally {
            this.toggleBusy();
          }
        },

        modalAddCustomer(){
          this.$bvModal.show("add_new_customer");
        },

        modalAddCustomerFromTicketing(){
          this.$bvModal.show("add_new_customer_from_ticketing");
        },

        getFilters(){
          var filter_string = '&limit=10000';
          filter_string += (this.filterInput.first_name) ? '&first_name='+this.filterInput.first_name : "";
          filter_string += (this.filterInput.last_name) ? '&last_name='+this.filterInput.last_name : "";
          filter_string += (this.filterInput.gender.length) ? '&gender='+this.filterInput.gender : "";
          filter_string += (this.filterInput.email) ? '&email='+this.filterInput.email : "";

          if(this.filterInput.birthdate.length == 2){
            filter_string += (this.filterInput.birthdate[0]) ? '&birthdate_s='+moment(this.filterInput.birthdate[0]).format('YYYY-MM-DD') : "";
            filter_string += (this.filterInput.birthdate[1]) ? '&birthdate_e='+moment(this.filterInput.birthdate[1]).format('YYYY-MM-DD') : "";
          }

          if(this.filterInput.created.length == 2){
            filter_string += (this.filterInput.created[0]) ? '&created_s='+moment(this.filterInput.created[0]).format('YYYY-MM-DD') : "";
            filter_string += (this.filterInput.created[1]) ? '&created_e='+moment(this.filterInput.created[1]).format('YYYY-MM-DD') : "";
          }

          filter_string += (this.filterInput.userStatus.length) ? '&user_status='+JSON.stringify(this.filterInput.userStatus) : "";
          filter_string += (this.filterInput.attributes.length) ? '&attributes='+JSON.stringify(this.attributeVals) : "";

          filter_string += (this.filterInput.address) ? '&address='+this.filterInput.address : "";
          filter_string += (this.filterInput.postal) ? '&postal='+this.filterInput.postal : "";
          filter_string += (this.filterInput.city) ? '&city='+this.filterInput.city : "";

          filter_string += (this.filterInput.country.length) ? '&country='+JSON.stringify(this.countryVals) : "";

          filter_string += (this.filterInput.provider_ticketing_id) ? '&provider_ticketing_id='+this.filterInput.provider_ticketing_id : "";
          filter_string += (this.filterInput.ewallet_serial) ? '&ewallet_serial='+this.filterInput.ewallet_serial : "";


          filter_string += (this.filterInput.season_ticket) ? '&season_ticket=Y' : "";

          return filter_string = filter_string.substring(1);
        },

        async getUserStatuses(){
          try {
            await Customers.getUserStatuses({

            }).then((response) => {
              var statuses = [];
              response.data.forEach(function (item) {
                if(item.status){
                  statuses.push(item.status);
                }
              });
              this.userStatuses = statuses;
            }).catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error)
            }).finally(() => {
            })

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error)
          }
        },

        async getCountry() {
          try {
            await Customers.getCustomersCountries({

            }).then((response) => {
              this.countries = response.data;
            }).catch(error => {
              this.error = error.response.data.error ? error.response.data.error : "";
              this.failedmsg(this.error)
            }).finally(() => {
            })

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error)
          }
        },

        async showCustomerMapData(){
          this.showMapData = !this.showMapData

          if(this.showMapData){
            this.showMap = true;
          }

        }
      },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col-md-12">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light me-3"
                     @click="modalAddCustomer">
                    <i class="mdi mdi-plus me-2"></i>
                    Create New
                  </a>
                  <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                      v-if="showButtonCreateUserFromTicketing"
                      @click="modalAddCustomerFromTicketing">
                    <i class="mdi mdi-plus me-2"></i>
                    Create User From Ticketing
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                          class="text-body fw-semibold pb-2 d-block"
                          data-toggle="collapse"
                          href="javascript: void(0);"
                          role="button"
                          aria-expanded="false"
                          v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                      <div class="row">
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="First name" label-for="formrow-firstname-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-firstname-input" ref="first_nameAutocomplete"
                                    v-model.trim="filterInput.first_name"
                                    :data=customerData
                                    :serializer="s => s.text"/>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Last name" label-for="formrow-lastname-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-lastname-input" ref="last_nameAutocomplete"
                                     v-model.trim="filterInput.last_name"
                                     :data=customerData
                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Gender" class="mb-3">
                            <b-form-checkbox-group name="gender" :options="genderOptions"
                                                   v-model="filterInput.gender"></b-form-checkbox-group>
                          </b-form-group>

                        </div>
                        <div class="col-sm-12 col-md-3 ">
                          <b-form-group label="User Attributes" label-for="formrow-attributes-input" class="mb-3">
                            <multiselect
                                    v-model="filterInput.attributes"
                                    :options="userAttributes"
                                    :multiple="true"
                                    label="text"
                                    track-by="value"
                            ></multiselect>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-3">
                          <div class="form-group mb-3">
                            <b-form-group label="Email" label-for="formrow-email-input" class="mb-3">
                              <vue-bootstrap-typeahead id="formrow-email-input" ref="emailAutocomplete"
                                                       v-model.trim="filterInput.email"
                                                       :data=customerData
                                                       :serializer="s => s.text"/>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <div class="form-group mb-3">

                            <b-form-group label="Phone" label-for="formrow-phone-input" class="mb-3">
                              <vue-bootstrap-typeahead id="formrow-phone-input" ref="phoneAutocomplete"
                                                       v-model.trim="filterInput.phone"
                                                       :data=customerData
                                                       :serializer="s => s.text"/>
                            </b-form-group>

                          </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Birthdate" label-for="formrow-birthdate-input" class="mb-3">
                            <date-picker
                                    v-model="filterInput.birthdate"
                                    range
                                    append-to-body
                                    lang="en"
                                    confirm
                                    format="DD/MM/YYYY"
                            ></date-picker>
                          </b-form-group>

                        </div>
                        <div class="col-sm-12 col-md-3">
                          <div class="form-group mb-3">
                            <b-form-group label="Created" label-for="formrow-created-input" class="mb-3">
                              <date-picker
                                      v-model="filterInput.created"
                                      range
                                      append-to-body
                                      lang="en"
                                      confirm
                                      format="DD/MM/YYYY"
                              ></date-picker>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Street Address" label-for="formrow-address-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-address-input" ref="addressAutocomplete"
                                                     v-model.trim="filterInput.address"
                                                     :data=customerData
                                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Postal Code" label-for="formrow-postal-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-postal-input" ref="postalAutocomplete"
                                                     v-model.trim="filterInput.postal"
                                                     :data=customerData
                                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="City" label-for="formrow-city-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-city-input" ref="cityAutocomplete"
                                                     v-model.trim="filterInput.city"
                                                     :data=customerData
                                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="Country" label-for="formrow-country-input" class="mb-3">
                            <multiselect
                                    v-model="filterInput.country"
                                    :options="countries"
                                    :multiple="true"
                                    label="country_name"
                                    track-by="name"
                                    value="name"
                            ></multiselect>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-3" v-if="accessView.includes('ticketing')">
                          <b-form-group label="Ticketing Customer Number" label-for="formrow-provider_ticketing_id-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-provider_ticketing_id-input" ref="provider_ticketing_idAutocomplete"
                                                     v-model.trim="filterInput.provider_ticketing_id"
                                                     :data=customerData
                                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>

                        <div class="col-sm-12 col-md-3" v-if="accessView.includes('cashless')">
                          <b-form-group label="Card Serial Number" label-for="formrow-card_serial_number-input" class="mb-3">
                            <vue-bootstrap-typeahead id="formrow-card_serial_number-input" ref="ewallet_serialAutocomplete"
                                                     v-model.trim="filterInput.ewallet_serial"
                                                     :data=customerData
                                                     :serializer="s => s.text"/>
                          </b-form-group>
                        </div>

                        <div class="col-sm-12 col-md-3">
                          <b-form-group label="User Status" label-for="formrow-userstatus-input" class="mb-3">
                          <multiselect
                                  v-model="filterInput.userStatus"
                                  :options="userStatuses"
                                  :multiple="true"
                          ></multiselect>
                          </b-form-group>
                        </div>

                        <div class="col-sm-12 col-md-3" v-if="accessView.includes('ticketing')">
                          <b-form-group class="mt-4 ">
                            <b-form-checkbox class="d-inline-block ms-1" id="season_holder" v-model="filterInput.season_ticket" name="season_ticket">
                              <span class="px-1">Season Holder</span>
                            </b-form-checkbox>
                          </b-form-group>

                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getCustomers">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>

                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="card" v-show="showMapData">
          <div class="card-body">
            <div class="row">
              <div class="col mb-3">
                <strong class="font-size-18">User Map Data</strong>
                <a href="javascript:void(0);" class="card-title float-end" @click="showMapData = !showMapData">X</a>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <CustomersMap :customerData="this.tableData" :showMapBool="this.showMap" />
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-show="filterData">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center" >
                <h4 class="card-title m-0">Total Users: {{totalRows}}</h4>
                <button type="button" class="btn btn-primary mx-5 w-sm" @click="showCustomerMapData"><i class="uil uil-map-marker-alt font-size-18"></i> Show Map</button>
              </div>
            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-show="filterData">
              <b-table
                      :items="tableData"
                      :busy="isBusy"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      empty-text="No Data Found"
              >

                <template v-slot:cell(first_name)="data">
                  {{ data.item.first_name + ' ' + data.item.middle_name + ' ' +  data.item.last_name }}
                </template>

                <template v-slot:cell(email)="data">
                    {{ data.item.email }}
                </template>

                <template v-slot:cell(status)="data">
                  <div class="font-size-16" v-html="format_status(data.item.status)"></div>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.customer_id}`}">
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                  </ul>
                </template>

              </b-table>
            </div>
            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MODALS -->
    <modalAddCustomer @onRefresh="getCustomers()"></modalAddCustomer>
    <modalAddCustomerFromTicketing ></modalAddCustomerFromTicketing>
    <!-- /MODALS -->

  </Layout>
</template>
